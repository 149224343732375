<template>
  <div>
    <b-card title="" header-tag="header" class="card-custom">
      <template v-slot:header>
        <div class="card-title m-0">
          <h3 class="card-label m-0">{{ title }}</h3>
        </div>
        <div class="card-toolbar"></div>
      </template>

      <form class="form" novalidate="novalidate" id="product_basic">
    
        <b-row>
        
          <b-col md="12">
            <b-form-group class="fw-600">
              <b-form-textarea
                name="reply"
                v-model="form.reply"
                placeholder=""
                rows="3"
                max-rows="6"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
      
        </b-row>

  

  
        <b-row>
          <b-col class="text-right">
            <button ref="kt_login_signin_submit" class="btn btn-primary">
              <i class="las la-save"></i> Save
            </button>
          </b-col>
        
        </b-row>
      </form>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Editor from "primevue/editor";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import BlogService from "@/core/services/api/blog/blog";
import Swal from "sweetalert2";
export default {
  components: { Editor },
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      title: "Reply",
      loading: false,
      form: {
        reply:""
      },
    };
  },
  mounted() {
    var vm = this;

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Comments",
        route: "/comments/"+this.id,
      },
      {
        title: "Reply Comment",
      },
    ]);
    const signin_form = KTUtil.getById("product_basic");
    this.fv = formValidation(signin_form, {
      fields: {
        reply: {
          validators: {
            notEmpty: {
              message: "Replay is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    this.fv.on("core.form.valid", async () => {
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right", "disabled");

      const formData = new FormData();
      formData.append("reply", JSON.stringify(vm.form.reply));
    
      await this.replyComment(formData, function (response) {
        vm.response(response, submitButton);
      });
    });
  },
  methods: {
    response(response, submitButton) {
      var vm = this;
      if (response) {
        let timerInterval;
        Swal.fire({
          title: response.message,
          timer: 500,
          icon: "success",
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          vm.$router.push({ path: "/comments/"+vm.id });
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
          }
        });
      }
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
    },
    replyComment(formData, callback) {
      var vm = this;
      BlogService.replyComment(formData, this.id)
        .then(function (response) {
          callback(response);
        })
        .catch((err) => {
          callback(false);
        });
    },
  },
};
</script>

<style></style>
